import PropTypes from 'prop-types';
import React from 'react';
import RegulatoryNotice from '@components/vehiclefleet/RegulatoryNotice';
import { Box, Container } from '@material-ui/core';
import PageContainer from '../organisms/PageContainer';

export default function DefaultTemplate(props) {
  const { children } = props;

  return (
    <PageContainer>
      {children}
      <Container>
        <Box my={5}>
          <RegulatoryNotice />
        </Box>
      </Container>
    </PageContainer>
  );
}

DefaultTemplate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
DefaultTemplate.defaultProps = {
  children: null,
};
