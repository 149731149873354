import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

export default function CarBrandTitle({ value, highlight }) {
  const classes = useStyles();

  if (!value) {
    return '';
  }

  const title = transformBrandTitle(value);

  if (!highlight) {
    return title;
  }

  return (
    <span className={classes.highlight}>
      {title}
    </span>
  );
}

CarBrandTitle.propTypes = {
  value: PropTypes.string,
  highlight: PropTypes.bool,
};
CarBrandTitle.defaultProps = {
  value: '',
  highlight: false,
};

const useStyles = makeStyles((theme) => ({
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function transformBrandTitle(value) {
  if (!value) {
    return '';
  }

  if (value === 'MG') {
    return value;
  }

  return startCase(toLower(value));
}
