import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { FiPercent } from 'react-icons/fi';

export default function SaleBadge() {
  const classes = useStyles();
  return (
    <div className={classes.burst}>
      <FiPercent className={classes.icon} />
    </div>
  );
}

const SIZE = 32;

const useStyles = makeStyles((theme) => ({
  burst: {
    backgroundColor: theme.palette.primary.main,
    width: SIZE,
    height: SIZE,
    margin: theme.spacing(1),
    position: 'relative',
    textAlign: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: SIZE,
      width: SIZE,
      backgroundColor: theme.palette.primary.main,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: SIZE,
      width: SIZE,
      backgroundColor: theme.palette.primary.main,
    },
    '&:before': {
      transform: 'rotate(30deg)',
    },
    '&:after': {
      transform: 'rotate(60deg)',
    },
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: theme.palette.primary.contrastText,
    zIndex: 1,
    fontSize: SIZE * 0.65,
  },
}));
