import getConfig from 'next/config';
import orderBy from 'lodash/orderBy';

const { publicRuntimeConfig } = getConfig();
const { CAR_SEARCH_ASSET_PREFIX } = publicRuntimeConfig;
const DEFAULT_IMAGE_SMALL = '/images/car-search/car-search_car-item_default_small.jpg';
const DEFAULT_IMAGE_LARGE = '/images/car-search/car-search_car-item_default_large.jpg';

export default function getCarImages(item, largeImages = false) {
  const images = [];
  const keys = orderBy(Object
    .keys(item?.attributes.carAttributes?.images ?? {}).sort(), (key) => key.length);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    const sizes = item.attributes.carAttributes.images[key];
    const path = largeImages ? sizes[1280] : sizes[500];

    images.push(CAR_SEARCH_ASSET_PREFIX + path);
  }

  if (images.length === 0) {
    images.push(
      largeImages ? DEFAULT_IMAGE_LARGE : DEFAULT_IMAGE_SMALL,
    );
  }

  return images;
}
