import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

export default function PageContainer(props) {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
}

PageContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
PageContainer.defaultProps = {
  children: null,
};

const useStyles = makeStyles((/* theme */) => ({
  root: {
    // TODO: Check this
    // overflowY: 'hidden' // BUGFIX: overflowing content breaks responsive layout
  },
}));
