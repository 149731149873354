import {
  Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Chip, Grid, Typography,
} from '@material-ui/core';
import { getCarImages, getPrice, mapCarCategory } from '@plugins/vehiclefleetapp';
import {
  FiArrowRight, FiCalendar, FiCheck, FiCheckCircle, FiClock, FiShoppingCart,
} from 'react-icons/fi';
import { getCarCheckoutRoute, getCarDetailPageRoute } from '@lib/routing';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import SaleBadge from '@components/atoms/SaleBadge';
import ModelName, { isNModel } from '@components/vehiclefleet/ModelName';
import clsx from 'clsx';
import ConsumptionEmissionsNotice, { GRAPHQL_REQUIRED_PROPS_FOR_CONSUMPTION } from '@components/vehiclefleet/ConsumptionEmissionsNotice';
import CarBrandTitle from './CarBrandTitle';
import Image from '../atoms/Image';
import Link from '../atoms/Link';

export default function InventoryCarListItem(props) {
  const {
    item,
    isPurchasingEnabled,
    variant,
    useSkeleton,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const cardElevation = 3;

  if (useSkeleton) {
    return (
      <div className={classes.root}>
        <Card elevation={cardElevation}>
          <CardHeader
            avatar={
              <Skeleton height={40} variant="circle" width={40} />
            }
            title={(
              <>
                <Skeleton />
                <Skeleton />
              </>
            )}
          />
          <Skeleton height={270} variant="rect" />
          <CardContent>
            <div style={{ height: 300 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  const isHighlighted = isNModel(item.attributes.model);
  const carCategory = mapCarCategory(item.attributes.category);
  const images = getCarImages(item);

  const detailUrl = getCarDetailPageRoute(item.attributes.slug, i18n.language);
  const financingUrl = `${detailUrl}#show-financing-calculator`;
  const checkoutUrl = getCarCheckoutRoute(item.attributes, i18n.language);
  const modelDetails = (item.attributes.model ?? '')
    .replace(item.attributes.baselineModel, '').trim();

  const deliveryDate = item.attributes.deliveryDate
  && DateTime.fromISO(item.attributes.deliveryDate).startOf('day') > DateTime.now().startOf('day')
    ? DateTime.fromISO(item.attributes.deliveryDate).toFormat('dd.MM.yyyy')
    : null;

  let carState = 'usedVehicle';

  if (!item.attributes.initialRegistrationDate) {
    carState = 'newVehicle';
  } else if (item.attributes.isDemonstrationVehicle) {
    carState = 'demonstrationCar';
  } else if (item.attributes.isDailyAdmission) {
    carState = 'dailyAdmission';
  }

  return (
    <div className={classes.root}>
      <Card
        className={clsx(classes.container, {
          [classes.highlighted]: isHighlighted,
        })}
        elevation={cardElevation}
      >
        <Link href={detailUrl}>
          <CardActionArea className={classes.contentContainer}>
            <CardHeader
              avatar={
                BRAND_LOGO_MAP[item.attributes.brand] ? (
                  <Image
                    alt={`${item.attributes.brand} Logo`}
                    className={classes.cardLogo}
                    src={`/images/car-brands/${BRAND_LOGO_MAP[item.attributes.brand]}`}
                  />
                ) : null
              }
              className={classes.cardHeader}
              disableTypography
              subheader={(
                <ModelName name={modelDetails} variant="body2" />
              )}
              title={(
                <Typography
                  className={classes.title}
                  component="div"
                  variant="h6"
                >
                  <CarBrandTitle value={item.attributes.brand} />
                  {' '}
                  {item.attributes.baselineModel}
                  {isHighlighted && (
                    <>
                      {' '}
                      <img
                        alt="N"
                        height={30}
                        src="/images/hyundai-n-logo.svg"
                        style={{
                          verticalAlign: 'middle',
                          marginTop: -6,
                        }}
                        width={48}
                      />
                    </>
                  )}
                  {item.attributes.model.includes('N-L') && ' N-Line'}
                </Typography>
              )}
            />
            <Image
              alt={`${item.attributes.brand} ${item.attributes.model}`}
              className={classes.cardMedia}
              isFluid
              isSquare
              src={images[0]}
            />
            <CardContent>
              <Grid alignItems="center" container>
                <Grid item style={{ marginRight: 'auto', alignSelf: 'flex-start' }} width="auto">
                  {item.attributes.leasingRate && (
                  <Typography component="div" style={{ lineHeight: '1' }} variant="h6">
                    <Typography component="span" variant="caption">
                      {t('components.vehiclefleet.InventoryCarListItem.leasingRate')}
                      {' '}
                    </Typography>
                    <strong>
                      {item.attributes.leasingRate.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0,
                      })}
                      {' mtl.'}
                      <sup>II</sup>
                    </strong>
                  </Typography>
                  )}
                </Grid>
                <Grid item width="auto">
                  {item.attributes.isSale && item.attributes.previousPrice && (
                    <Box display="flex">
                      <Typography className={classes.salePrice} component="div" variant="subtitle2">
                        <strong>{getPrice(item.attributes, false, 'previousPrice')}</strong>
                      </Typography>
                    </Box>
                  )}
                  <Typography component="div">
                    <Typography component="div" style={{ lineHeight: '1' }} variant="h6">
                      <strong>
                        {getPrice(item.attributes)}
                      </strong>
                    </Typography>
                    <Typography component="div" variant="caption">
                      {t('components.vehiclefleet.InventoryCarListItem.vatIncluded')}
                    </Typography>
                  </Typography>
                </Grid>
                {item.attributes.isSale && (
                  <Grid className={classes.saleBadge} item width="auto">
                    <SaleBadge />
                  </Grid>
                )}
              </Grid>
              <Box my={1}>
                <Chip
                  className={classes.cardBadge}
                  color="primary"
                  label={t(`components.vehiclefleet.InventoryCarListItem.vehicleTypes.${carState}`)}
                  size="small"
                />
              </Box>
              <Box mb={2}>
                <Typography component="div" variant="body2">
                  {t('components.vehiclefleet.InventoryCarListItem.location')}
                  {' '}
                  <strong>{item.attributes.branch.data?.attributes.name}</strong>
                </Typography>
                <Grid alignItems="center" container>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body2">
                      {i18n.exists(`components.vehiclefleet.InventoryCarListItem.vehicleCategories.${carCategory.vehicleCategory}`)
                        ? t(`components.vehiclefleet.InventoryCarListItem.vehicleCategories.${carCategory.vehicleCategory}`) : carCategory.vehicleCategory}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body2">
                      {item.attributes.power}
                      {t('components.vehiclefleet.InventoryCarListItem.kW')}
                      (
                      {item.attributes.horsePower}
                      {' '}
                      {t('components.vehiclefleet.InventoryCarListItem.ps')}
                      )
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {item.attributes.mileage.toLocaleString('de-DE')}
                    {' km'}
                  </Grid>
                  {item.attributes.initialRegistrationDate && (
                    <Grid item xs={6}>
                      <Typography component="div" variant="body2">
                        {`EZ ${DateTime
                          .fromISO(item.attributes.initialRegistrationDate)
                          .toFormat('MM-yyyy')}`}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Typography component="div" variant="body2">
                      {t(`components.vehiclefleet.InventoryCarListItem.fuelTypes.${item.attributes.isPluginHybrid ? 'plugInHybrid' : item.attributes.fuelType}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body2">
                      {t(`components.vehiclefleet.InventoryCarListItem.transmissionTypes.${item.attributes.transmissionType}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography component="div" variant="body2">
                      {i18n.exists(`components.vehiclefleet.InventoryCarListItem.colors.${item.attributes.color}`)
                        ? t(`components.vehiclefleet.InventoryCarListItem.colors.${item.attributes.color}`) : item.attributes.color}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {deliveryDate && (
                <Box my={1}>
                  <Chip
                    color="default"
                    icon={<FiCalendar />}
                    label={(
                      <span>
                        {t('components.vehiclefleet.InventoryCarListItem.estimatedDeliveryDate')}
                        {' '}
                        <strong>
                          {deliveryDate}
                        </strong>
                      </span>
                    )}
                    size="small"
                  />
                </Box>
              )}
              {item.attributes.isImmediatelyAvailable ? (
                <Box my={1}>
                  <Link href={`${detailUrl}#show-contact-form`}>
                    <Chip
                      className={classes.availableNowBadge}
                      color="primary"
                      icon={<FiCheck />}
                      label={(
                        <span>
                          {t('components.vehiclefleet.InventoryCarListItem.availableImmediately')}
                        </span>
                      )}
                      size="small"
                    />
                  </Link>
                </Box>
              ) : (
                <Box my={1}>
                  <Link href={`${detailUrl}#show-contact-form`}>
                    <Chip
                      color="primary"
                      icon={<FiClock />}
                      label={(
                        <span>
                          {t('components.vehiclefleet.InventoryCarListItem.canBeOrderedImmediately')}
                        </span>
                      )}
                      size="small"
                    />
                  </Link>
                </Box>
              )}
              <Box>
                <ConsumptionEmissionsNotice
                  car={item}
                  variant="compact"
                />
              </Box>
            </CardContent>
          </CardActionArea>
        </Link>
        {variant === 'default' && (
          <CardActions className={classes.cardActions}>
            <Link href={detailUrl} legacyBehavior>
              <Button
                component="a"
                startIcon={<FiArrowRight />}
              >
                {t('components.vehiclefleet.InventoryCarListItem.details')}
              </Button>
            </Link>
            {!item.attributes.isPurchased && (
              <>
                <Link href={financingUrl} legacyBehavior>
                  <Button
                    color="primary"
                    component="a"
                    startIcon={<FiCheckCircle />}
                  >
                    {t('components.vehiclefleet.InventoryCarListItem.finance')}
                  </Button>
                </Link>
                {isPurchasingEnabled && (
                  <Link href={checkoutUrl} legacyBehavior>
                    <Button
                      color="primary"
                      component="a"
                      startIcon={<FiShoppingCart />}
                    >
                      {t('components.vehiclefleet.InventoryCarListItem.order')}
                    </Button>
                  </Link>
                )}
              </>
            )}
          </CardActions>
        )}
      </Card>
    </div>
  );
}
InventoryCarListItem.propTypes = {
  item: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    price: PropTypes.number,
  }),
  variant: PropTypes.oneOf([
    'default',
    'checkout',
  ]),
  useSkeleton: PropTypes.bool,
  isPurchasingEnabled: PropTypes.bool,
};
InventoryCarListItem.defaultProps = {
  item: {},
  variant: 'default',
  useSkeleton: false,
  isPurchasingEnabled: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 0, // Important for truncating the header text
    '& *': {
      textDecoration: 'none !important',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 3,
    borderColor: theme.palette.quaternary.main,
    borderBottomColor: theme.palette.primary.main,
  },
  highlighted: {
    borderLeftColor: '#FF241C',
    borderTopColor: '#FF241C',
    borderRightColor: '#252021',
    borderBottomColor: '#252021',
  },
  contentContainer: {
    flex: 1,
  },
  cardHeader: {
    '& > div': {
      minWidth: 0,
    },
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
  },
  cardMedia: {},
  cardLogo: {
    display: 'block',
    width: 38,
    height: 38,
  },
  cardActions: {
    flexWrap: 'wrap',
  },
  cardBadge: {
    marginRight: theme.spacing(0.5),
  },
  availableNowBadge: {
    backgroundColor: '#2E7D32',
  },
  salePrice: {
    fontSize: '0.95rem',
    textDecoration: 'none',
    position: 'relative',
    '&::before': {
      top: '40%', /* tweak this to adjust the vertical position if it's off a bit due to your font family */
      background: theme.palette.primary.main,
      opacity: 0.70,
      content: '""',
      width: '120%',
      position: 'absolute',
      height: '.35em',
      borderRadius: '.1em',
      left: '-10%',
      whiteSpace: 'nowrap',
      display: 'block',
      transform: 'rotate(-15deg)',
    },
  },
  saleBadge: {
    marginLeft: theme.spacing(1),
  },
}));

export const BRAND_LOGO_MAP = {
  HYUNDAI: 'hyundai-motor-company-logo-square.svg',
  MITSUBISHI: 'mitsubishi-logo.svg',
  FUSO: 'mitsubishi-fuso-logo-square.svg',
  MG: 'mgmotors-logo-square.svg',
  MAXUS: 'maxus-logo.png',
};

export const GRAPHQL_REQUIRED_PROPS = `
  id
  attributes {
    internalId
    isDeleted
    isPurchased
    slug
    branch {
      data {
        id
        attributes {
          branchId
          name
        }
      }
    }
    category
    brand
    baselineModel
    model
    power
    horsePower
    isNewCar
    isPluginHybrid
    isPluginHybrid
    generalInspectionDate
    initialRegistrationDate
    mileage
    price
    previousPrice
    originalPrice
    currency
    isVatAvailable
    isOldtimer
    isSale
    vehicleIdentificationNumber
    color
    isPurchased
    hsn
    tsn
    fuelType
    transmissionType
    pollutantClass
    deliveryDate
    isImmediatelyAvailable
    isDemonstrationVehicle
    isDailyAdmission
    carAttributes
    leasingRate
    ${GRAPHQL_REQUIRED_PROPS_FOR_CONSUMPTION}
  }
`;
